import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./ProgressionForm.css";
import ProgressFormInput from "../ProgressFormInput";
import Button from "../../Button";
import useAnalytics from "../../../hooks/useAnalytics";

const ProgressionForm = ({
  questions,
  formState,
  setFormState,
  selectedFormData,
  handleSubmitForm,
  formStyle,
}) => {
  const className = "ProgressionForm";
  const { trackFormEvent } = useAnalytics();

  const formHook = useForm();
  const { register, handleSubmit, setValue, watch } = formHook;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const question = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const onSubmit = (data) => {
    console.log(data);
    // Add any additional submit logic here
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
  };

  const handleFormChange = () => {
    const values = formHook.getValues();
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
    setFormState({
      values,
      isValid: !Object.values(values).includes(null),
    });
  };

  const handleNext = (option, name, e) => {
    e.stopPropagation();
    if (isLastQuestion) return;
    else setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
  };

  const handlePrev = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
  };

  const length = selectedFormData.questions.length;
  const { scaleTwoStart = length - 1, scaleTwo } = selectedFormData;
  const doesScaleScaleTwoExist = !!scaleTwo;
  const chooseOptions = (question, index) => {
    const scale = selectedFormData.scale || question.answers;

    if (!doesScaleScaleTwoExist) return scale;

    const scaletoUse =
      index + 1 < scaleTwoStart ? scale : selectedFormData.scaleTwo;
    return scaletoUse;
  };

  // const scaleTwoPrompt = (index) => {
  //   if (!doesScaleScaleTwoExist) return "";

  //   const useAnotherPrompt = index + 1 === scaleTwoStart;
  //   const prompt = useAnotherPrompt ? selectedFormData.promptTwo : "";
  //   return prompt;
  // };

  const titleParts = (index) => {
    if (selectedFormData.parts[0].startingPoint === index)
      return <h2>{selectedFormData.parts[0].name}</h2>;
    if (selectedFormData.parts[1].startingPoint === index)
      return <h2>{selectedFormData.parts[1].name}</h2>;
  };

  if (!question) return;

  const isFormComplete = () => {
    const values = formHook.getValues();
    return Object.values(values).length === questions.length;
  };
  // const watchedValue = watch("nervous", ""); // using name since it's passed as a prop

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
        <div key={`question_${question.name}`}>
          {selectedFormData.moreThanOnePart
            ? titleParts(currentQuestionIndex)
            : ""}
          <b>
            {
              //<strong>{scaleTwoPrompt(currentQuestionIndex)}</strong>
            }
          </b>
          <div className={`${className}-question`}>{question.question}</div>
          {question.description && (
            <div className={`${className}-description`}>
              {question.description || ""}
            </div>
          )}
          <ProgressFormInput
            options={chooseOptions(question, currentQuestionIndex)}
            onClick={handleNext}
            register={register}
            name={question.name}
            setValue={setValue}
            label={`${currentQuestionIndex + 1}. ${question.question}`}
            secondLabel={question.description || ""}
          />
          {/*
          <RadioInput
            options={chooseOptions(question, currentQuestionIndex)}
            onClick={handleNext}
            register={register}
            name={question.name}
            label={`${currentQuestionIndex + 1}. ${question.question}`}
            secondLabel={question.description || ""}
          />
          */}
        </div>

        <div className={`${className}-button-container`}>
          {currentQuestionIndex > 0 && (
            <Button type="tertiary" onClick={handlePrev}>
              Previous
            </Button>
          )}
          {isFormComplete() && (
            <Button type="secondary" onClick={handleSubmitForm}>
              Submit
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ProgressionForm;

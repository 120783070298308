import { AddictionCardImage } from "../../images/AddictionCardImage";
import { ADHDCardImage } from "../../images/ADHDCardImage";
import { AnxietyCardImage } from "../../images/AnxietyCardImage";
import { DepressionCardImage } from "../../images/DepressionCardImage";
import { EatingCardImage } from "../../images/EatingCardImage";
import { LonelinessCardImage } from "../../images/LonelinessCardImage";
import { NewStressCardImage } from "../../images/NewStressCardImage";
import { SelfEsteemCardImage } from "../../images/SelfEsteemCardImage";
import { StressCardImage } from "../../images/StressCardImage";
import { WellBeingCardImage } from "../../images/WellBeingCardImage";

export const checkupData = [
  {
    name: "Depression",
    duration: "<2 min",
    isDone: false,
    key: "phq-9",
    testLink: "/wellness/test/phq-9",
    description:
      "This assessment explores the symptoms and severity of depression, helping you recognize patterns and triggers.",
    descriptionMobile: "Identify depression",
    image: <DepressionCardImage />,
    smallImage: <DepressionCardImage size={0.66} />,
  },
  {
    name: "Anxiety",
    duration: "<2 min",
    isDone: false,
    key: "gad-7",
    testLink: "/wellness/test/gad-7",
    description:
      "Uncover the underlying causes of anxiety and identify strategies for management.",
    descriptionMobile: "Identify anxiety",
    image: <AnxietyCardImage />,
    smallImage: <AnxietyCardImage size={0.5} />,
  },
  {
    name: "Self-esteem",
    duration: "<2 min",
    isDone: false,
    key: "rosenberg-self-esteem",
    testLink: "/wellness/test/rosenberg-self-esteem",
    description:
      "Analyze your self-perception, confidence, and dive into the underlying factors that influence your self-esteem.",
    descriptionMobile: "Identify your level of self-esteem",
    image: <SelfEsteemCardImage />,
    smallImage: <SelfEsteemCardImage size={0.66} />,
  },
  {
    name: "Loneliness",
    duration: "<3 min",
    isDone: false,
    key: "ucla-loneliness",
    testLink: "/wellness/test/ucla-loneliness",
    description:
      "Assess feelings of loneliness, isolation, and thoughtfully explore pathways to connect with others.",
    descriptionMobile: "Identify if you're feeling lonely",
    image: <LonelinessCardImage />,
    smallImage: <LonelinessCardImage size={0.6} />,
  },

  {
    name: "Eating",
    duration: "<3 min",
    isDone: false,
    key: "eating",
    testLink: "/wellness/test/eat26",
    description: "Explore if you have signs of an eating disorder",
    descriptionMobile: "Explore if you have signs of an eating disorder",
    image: <EatingCardImage />,
    smallImage: <EatingCardImage size={0.6} />,
  },

  {
    name: "Addiction",
    duration: "<3 min",
    isDone: false,
    key: "addiction",
    testLink: "/wellness/test/adult-alcohol",
    description: "Eating",
    descriptionMobile: "Identify if you're feeling lonely",
    image: <AddictionCardImage />,
    smallImage: <AddictionCardImage size={0.6} />,
  },
  {
    name: "Trauma",
    duration: "<3 min",
    isDone: false,
    key: "trauma",
    testLink: "/wellness/test/ptsd5",
    description: "PTSD",
    descriptionMobile: "Identify if you have signs of trauma",
    // image: <EatingCardImage />,
    // smallImage: <EatingCardImage size={0.6} />,
  },
  {
    name: "Well-being",
    duration: "1 min",
    isDone: false,
    key: "who5",
    testLink: "/wellness/test/who5",
    description:
      "This evaluation delves into various facets of your mental well-being, including stress levels, emotional balance, anxiety.",
    descriptionMobile: "Identify your wellness score",
    image: <WellBeingCardImage />,
    smallImage: <WellBeingCardImage size={0.5} />,
  },
  {
    name: "ADHD",
    duration: "<3 min",
    isDone: false,
    key: "adhd",
    testLink: "/wellness/test/ADHD_ASRS_Adult",
    description: "Explore if you have signs of ADHD",
    descriptionMobile: "Explore if you have signs of ADHD",
    image: <ADHDCardImage />,
    smallImage: <ADHDCardImage size={0.6} />,
  },
  {
    name: "Stress",
    duration: "<2 min",
    isDone: false,
    key: "pss",
    testLink: "/wellness/test/pss",
    description:
      "Assess your stress levels, pinpoint specific sources, and work to develop personalized coping strategies.",
    descriptionMobile: "See if you're experiencing stress",
    image: <NewStressCardImage size={0.5} />,
    smallImage: <NewStressCardImage size={0.8} />,
  },
];

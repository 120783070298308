import React, { useEffect } from "react";
import "../../../styles/NewReferral.css";
import ReferralCard from "./ReferralCard";
import mentalHealthProviders from "./MentalHealthProviders";
import IndividualTherapistCard from "./IndividualTherapistCard";
import IndividualMentalHealthProviders from "./IndividualMentalHealthProviders";

const NewReferrals = ({ filters, setNumberOfResults }) => {
  const isAgeInRange = (age, range) => {
    if (!range) return false;
    return age >= Math.min(...range) && age <= Math.max(...range);
  };

  const offersSetting = ({ virtualLocations, inPersonLocations }) => {
    const { state, setting } = filters;

    if (setting === "virtual") {
      if (!state) return virtualLocations?.length;
      return virtualLocations?.includes(filters.state);
    }
    if (setting === "inPerson") {
      if (!state) return inPersonLocations?.length;
      return inPersonLocations?.includes(filters.state);
    }
    if (setting === "hybrid") {
      if (!state) return virtualLocations?.length && inPersonLocations?.length;
      return (
        virtualLocations?.includes(filters.state) &&
        inPersonLocations?.includes(filters.state)
      );
    }
  };

  const filteredPlatforms = mentalHealthProviders.filter((platform) => {
    const { virtualLocations, inPersonLocations } = platform;
    return (
      (!filters.insurance || platform.insurances.includes(filters.insurance)) &&
      (!filters.state ||
        platform.virtualLocations?.includes(filters.state) ||
        platform.inPersonLocations?.includes(filters.state)) &&
      (!filters.age ||
        isAgeInRange(filters.age, platform.youthAgeRange) ||
        isAgeInRange(filters.age, platform.adultAgeRange)) &&
      (!filters.setting ||
        offersSetting({
          virtualLocations,
          inPersonLocations,
        }))
    );
  });

  const dynamicSort = (property) => {
    let sortOrder = 1;
    let key = property;

    if (property === "reversePrice") {
      sortOrder = -1;
      key = "price";
    } else if (property === "price") {
      key = "price";
    } else if (property === "alphabetical") {
      key = "companyName";
    } else if (property === "availability") {
      key = "availability";
    }

    return (a, b) => {
      if (typeof a[key] === "string") {
        a[key] = a[key].toLowerCase();
        b[key] = b[key].toLowerCase();
      }
      const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      return result * sortOrder;
    };
  };

  useEffect(() => {
    setNumberOfResults(filteredPlatforms.length);
  }, [filteredPlatforms]);

  const sortedFilteredPlatforms = filteredPlatforms.sort(
    dynamicSort(filters.sortBy)
  );

  return (
    <div className="new-referrals-container">
      {sortedFilteredPlatforms.map((company, index) => (
        <ReferralCard key={index} company={company} />
      ))}
      {/* {IndividualMentalHealthProviders.map((therapist, index) => (
        <IndividualTherapistCard index={index} therapist={therapist} />
      ))} */}
    </div>
  );
};

export default NewReferrals;

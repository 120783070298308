import { useEffect } from "react";

const BlueberryTallyPage = () => {
  useEffect(() => {
    // Dynamically load the Tally widget script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Load all Tally embeds on the page
      if (window.Tally) {
        window.Tally.loadEmbeds();
      }
    };

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginTop: "-100px !important",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <iframe
        data-tally-src="https://tally.so/r/w2JV8b"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="SelfCare Navigation Form for Blueberry"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          border: "0",
          marginTop: "-100px",
        }}
      ></iframe>
    </div>
  );
};
export default BlueberryTallyPage;

//   useEffect(() => {
//     // Dynamically load the Tally widget script
//     const script = document.createElement("script");
//     script.src = "https://tally.so/widgets/embed.js";
//     script.async = true;
//     document.head.appendChild(script);

//     script.onload = () => {
//       // Load all Tally embeds on the page
//       if (window.Tally) {
//         window.Tally.loadEmbeds();
//       }
//     };

//     return () => {
//       // Cleanup: remove the script when the component is unmounted
//       document.head.removeChild(script);
//     };
//   }, []);

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         width: "100%",
//         maxWidth: "1000px",
//         height: "auto",
//       }}
//     >
//       <iframe
//         data-tally-src="https://tally.so/embed/w2JV8b?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
//         loading="lazy"
//         width="100%"
//         height="1867"
//         frameBorder="0"
//         marginHeight="0"
//         marginWidth="0"
//         title="SelfCare Navigation Form for Blueberry"
//         style={{ border: "none" }}
//       ></iframe>
//     </div>
//   );
// };

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase.config";
import Button from "../components/Button/index";
import { ThemeContext } from "../contexts/ThemeContext";
import { useResponsive } from "../tools/device";
import { EveryJourney } from "../images/everyjourney";
import WellnessHomeStats from "./WellnessHomeStats";
// import MonthlyWellnessCheckin from "../images/MonthlyWellnessCheckin.png";
import { NewHomePage } from "../images/NewHomePage";
// import { SecondSlide } from "../images/secondslide";
import RecommendedScreenings from "./RecommendedScreenings";
import { NewHomePageMobile } from "../images/NewHomePageMobile";
// import UnlockYourPath from "./UnlockYourPath";
import mobileUnlockYourPath from "../images/unlockyourpathmobileclip.png";
import EveryJourneyMobile from "../images/everyjourneymobile.png";
import { SeeSaw } from "../images/seesaw";
import QuadSection from "./WellnessHomePage/QuadSection/QuadSection";
import NewLandingPage from "./NewHome/NewLandingPage";
import PartnersSection from "./NewHome/PartnersSection";
// import Testimonials from '../components/Testimonials';

const WellnessHome = () => {
  const [user, error] = useAuthState(auth);
  const { colorMode, setColorMode } = useContext(ThemeContext);
  const isMobile = useResponsive()?.isMobile;
  const navigate = useNavigate();

  return (
    <div className="wellness_homepage">
      <div className="newHero">
        {/* <NewLandingPage /> */}
        {isMobile ? <NewHomePageMobile /> : <NewHomePage />}
        <div className="hero_subtitle">
          Need mental health help but don't know where to start?
        </div>
        {!isMobile && (
          <div className="hero_button">
            <Button
              type="primary"
              extraClassName="different-hover with-border bigger-font"
              onClick={() => {
                navigate("/wellness/signup");
              }}
            >
              Take the assessment
            </Button>
            <span>or</span>
            <Button
              type="tertiary"
              extraClassName="wide no-background-color bigger-font"
              onClick={() => {
                navigate("/wellness/signup");
              }}
            >
              &nbsp;&nbsp;&nbsp;Find care&nbsp;&nbsp;&nbsp;
            </Button>
          </div>
        )}
      </div>
      {isMobile && <QuadSection isMobile={isMobile} />}
      {!isMobile && <QuadSection isMobile={isMobile} />}
      <PartnersSection />

      <div className="flex-width">
        <WellnessHomeStats />
      </div>
      {!isMobile && (
        <div className="recommended_screenings_container">
          <RecommendedScreenings user={user} />
        </div>
      )}
      {/* {!isMobile && <QuadSection />} */}

      {isMobile && (
        <>
          <h1 className="redefine">
            <SeeSaw size={0.5} />
            <div className="redefineTitle">
              Let's Redefine Wellness, <br /> Starting With You
            </div>
          </h1>
          <img src={mobileUnlockYourPath} alt="unlockyourpath" />
        </>
      )}
      {/* {isMobile ? (
        <img src={EveryJourneyMobile} alt="unlockyourpath" />
      ) : (
        <div className="checkup">
          <EveryJourney />
          <div className="checkup_button">
            <Button
              type="custom"
              onClick={() => {
                navigate("/wellness/signup");
              }}
            >
              Check in with Self
            </Button>
          </div>
        </div>
      )} */}
      {isMobile && (
        <div className="banner">
          <div className="content">
            <div className="checkup_button">
              {user ? (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("/wellness/dashboard");
                  }}
                  scrollToTop={true}
                >
                  Go to dashboard
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("/wellness/signup");
                  }}
                >
                  Take the Quiz
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WellnessHome;

import { useState, useEffect } from "react";
import { getDocs, collection, where, query } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";
import "./WellnessGettingStarted.css";
import Button from "../../components/Button";
// import TestLink from "../../components/TestLink";
import { checkupData } from "./checkinTestsData";
import { db, auth } from "../../firebase.config";
import { wasTestTakenInPastWeek } from "../../tools/timeTools";
import TestCard from "../../components/TestCards/TestCard";
import { useResponsive } from "../../tools/device";

const CheckinNew = () => {
  const className = "WellnessGettingStarted";
  const [tests, setTests] = useState(checkupData);
  const [user] = useAuthState(auth);
  const location = useLocation();
  const { from, data } = location?.state || {
    from: "unknown",
    data: "unknown",
  };
  const isMobile = useResponsive()?.isMobile;

  // const totalNumberOfCompletedTests = tests.filter((test) => !!test.isDone);
  const [isHovering, setIsHovering] = useState(false);
  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (user && user.uid) {
      const testsRef = collection(db, "WellnessTestResults");
      const q = query(testsRef, where("user", "==", user.uid));

      getDocs(q).then((res) => {
        const testsAsMap = {};
        res.forEach((doc) => {
          const testData = doc.data();
          if (testsAsMap[testData.formType]) {
            if (testData.created > testsAsMap[testData.formType].created) {
              testsAsMap[testData.formType] = testData;
            }
          } else {
            testsAsMap[testData.formType] = testData;
          }
        });
        const updatedTests = tests.map((test) => {
          if (testsAsMap[test.key]) {
            const wasTestTakenLessThanOneWeekAgo = wasTestTakenInPastWeek(
              testsAsMap[test.key]
            );
            return { ...test, isDone: wasTestTakenLessThanOneWeekAgo };
          } else {
            return { ...test, isDone: false };
          }
        });
        // Sort the array to move completed tests to the end
        updatedTests?.sort((a, b) => {
          if (a.isDone === b.isDone) {
            return 0; // keep original order if both are done or both not done
          }
          return a.isDone ? 1 : -1; // move completed tests to the end
        });
        setTests(updatedTests);
      });
    }
  }, [user]);

  return (
    <div className={`${className}-new`}>
      {data !== "NewLanding" && (
        <h1 className={`${className}-title-new`}>Your Wellness Checkin</h1>
      )}
      {data === "NewLanding" && (
        <h1 className={`${className}-title-new`}>
          Take a <br />
          Wellness Quiz
        </h1>
      )}
      <div className={`${className}-content-new`}>
        {data === "NewLanding" && (
          <div className={`${className}-spaceBottom-new`}>
            <p>select any of the following quizzes</p>
            <p className="answersPrivate">
              all your answers are kept private and secure.
            </p>
          </div>
        )}
        {data !== "NewLanding" && (
          <div className={`${className}-spaceBottom-new`}>
            In under 15 minutes, you’ll have an overview of how you are doing.
            <br />
            All your answers are kept private and secure.
          </div>
        )}
        {!isMobile && (
          <>
            <ul className={`${className}-testList-new`}>
              {tests.slice(0, 4).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} />
                  </li>
                );
              })}
            </ul>
            <ul className={`${className}-testList-new`}>
              {tests.slice(4, 7).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} />
                  </li>
                );
              })}
            </ul>
            <ul className={`${className}-testList-new`}>
              {tests.slice(7).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} />
                  </li>
                );
              })}
            </ul>
            {/* <ul className={`${className}-testList-new`}>
              {tests.slice(9).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} />
                  </li>
                );
              })}
            </ul> */}
          </>
        )}
        {isMobile && (
          <>
            <ul className={`${className}-testList-new`}>
              {tests.slice(0, 2).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} isMobile={isMobile} />
                  </li>
                );
              })}
            </ul>
            <ul className={`${className}-testList-new`}>
              {tests.slice(2, 4).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} isMobile={isMobile} />
                  </li>
                );
              })}
            </ul>
            <ul className={`${className}-testList-new`}>
              {tests.slice(4, 6).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} isMobile={isMobile} />
                  </li>
                );
              })}
            </ul>
            <ul className={`${className}-testList-new`}>
              {tests.slice(6, 8).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} isMobile={isMobile} />
                  </li>
                );
              })}
            </ul>
            <ul className={`${className}-testList-new`}>
              {tests.slice(8).map((test) => {
                return (
                  <li
                    key={`test-row-${test.testLink}`}
                    className={`${className}-testListItem-new`}
                  >
                    <TestCard test={test} isMobile={isMobile} />
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
      <div className={`${className}-buttonContainer-new`}>
        <Link
          to={user ? "/wellness/schedule-next-checkup" : "/wellness/dashboard"}
          state={{ from }}
          scrolltotop="true"
        >
          {console.log("location", location, from, data)}
          <div>
            {/* {totalNumberOfCompletedTests.length === 0 && isHovering && (
              <div className="popup-message">
                While we encourage you to begin the checkin, you are free to
                start later and explore.
              </div>
            )}
            {(totalNumberOfCompletedTests.length === 1 ||
              totalNumberOfCompletedTests.length === 2) &&
              isHovering && (
                <div className="popup-message">
                  Great job starting! We encourage you to finish the checkin,
                  but you can come back at any time.
                </div>
              )}
            {(totalNumberOfCompletedTests.length === 3 ||
              totalNumberOfCompletedTests.length === 4) &&
              isHovering && (
                <div className="popup-message">
                  You're almost done! Want to finish? If not, you can come back
                  at any time.
                </div>
              )}
            {totalNumberOfCompletedTests.length === 5 && isHovering && (
              <div className="popup-message">Great job doing your checkin!</div>
            )} */}
            <Button
              type="primary"
              handleHover={handleHover}
              handleMouseLeave={handleMouseLeave}
            >
              Go to Dashboard
            </Button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CheckinNew;

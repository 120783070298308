import React from "react";
import "./PartnersSection.css"; // External CSS for styling
import mentalHealthProviders from "../../components/Referrals/ReferralCard/MentalHealthProviders";

const PartnersSection = () => {
  return (
    <div className="partners-section">
      <div className="partners-container">
        <p className="partners-section-title">
          It's hard finding care. Self understands that.
        </p>
        <span className="partners-section-subtext">
          <br />
          So we partnered with the most trusted platforms to make it easier for
          you.
        </span>
      </div>
      <div className="partners-grid">
        {mentalHealthProviders.map((partner, index) =>
          partner.logo ? (
            <div
              className="partner-logo-container"
              key={index}
              style={{
                borderColor: partner.borderColor ? partner.borderColor : "#ccc",
              }}
            >
              <img
                src={partner.logo ? `/images/${partner.logo}` : null}
                alt={partner.companyName}
                className="partner-logo"
              />
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
};

export default PartnersSection;

import React, { useState, useEffect } from "react";
import {
  isPersonalDetailsFormCompleted,
  updateUserDocAtSignUp,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import "../styles/signUpWellness.scss";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import { Continents } from "../components/Continents";
import { SelectCountries } from "../components/Countries";
import { AgeSelect } from "../components/Age";
import { SexSelect } from "../components/Sex";
import { SelectState } from "../components/States";

const SignUpPersonalDetails = () => {
  const [formState, setFormState] = useState({
    signingUpFor: "",
    signingRelationship: "",
    age: "",
    guardian: "",
    sex: "",
    pregnant: "",
    // city: "",
    state: "",
    country: "",
    continent: "",
  });

  const [user] = useAuthState(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    age: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://ipapi.co/json")
      .then((response) => response.json())
      .then((data) => {
        setFormState({
          city: data.city,
          state: data.region_code,
          region: data.region,
          regionCode: data.region_code,
          country: data.country,
          continent: data.continent_code,
        });
      })
      .catch((error) => {
        setError((prev) => {
          const stateObj = { ...prev, signupError: error };
          return stateObj;
        });
        console.error("Error fetching location:", error);
      });
  }, []);

  const updatePersonalDetails = async (e) => {
    e.preventDefault();
    const result = await updateUserDocAtSignUp({
      userId: user.uid,
      age: formState.age,
      guardian: formState.guardian || null,
      sex: formState.sex,
      pregnant: formState.pregnant || null,
      // formState.city,
      state: formState.state,
      country: formState.country,
      continent: formState.continent,
      setIsLoading,
      setError,
    });
    return result;
  };

  const validateFormState = (e) => {
    let { name, value } = e.target;
    console.log("error", error, "name", name, value);
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "sex":
          if (!value) {
            stateObj[name] = "Please select sex at birth.";
          }
          break;

        case "age":
          if (!value) {
            stateObj[name] = "Please enter current age";
          }
          break;

        case "pregnant":
          if (!value) {
            stateObj[name] = "Please enter if pregnant.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateFormState(event);
  };

  return (
    <div className="signup-form-container">
      {!isLoading && (
        <>
          <h2>
            Just a few details so we can <br />
            personalize the experience for you.
          </h2>
          <form className="detailsForm">
            <div className="signing-up-for">
              <h3>Who are you signing up for?</h3>
              <div className="signing-up-for_options">
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    name="signingUpFor"
                    value="Myself"
                    id="myself"
                    checked={formState.signingUpFor === "Myself"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="myself">Myself</label>
                </div>
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    name="signingUpFor"
                    value="SomeoneElse"
                    id="someoneElse"
                    checked={formState.signingUpFor === "SomeoneElse"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="someoneElse">Someone Else</label>
                </div>
              </div>
            </div>
            {formState.signingUpFor === "SomeoneElse" && (
              <div className="specify-relationship">
                <h3>Who specifically?</h3>
                <div className="relationship_options">
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      name="signingRelationship"
                      value="Child"
                      id="child"
                      checked={formState.signingRelationship === "Child"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="child">Child</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      name="signingRelationship"
                      value="Mentee"
                      id="mentee"
                      checked={formState.signingRelationship === "Mentee"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="mentee">Mentee</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      name="signingRelationship"
                      value="Friend"
                      id="friend"
                      checked={formState.signingRelationship === "Friend"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="friend">Friend</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      name="signingRelationship"
                      value="Other"
                      id="other"
                      checked={formState.signingRelationship === "Other"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              </div>
            )}
            <div className="age">
              <h3>Age</h3>
              <div className="custom-select-container">
                <AgeSelect value={formState.age} onChange={handleInputChange} />
              </div>
            </div>
            <div className="location">
              <h3>Location</h3>
              {!formState.state && (
                <div className="custom-select-container">
                  <Continents
                    value={formState.continent}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              <div className="custom-select-container">
                <SelectCountries
                  continent={formState.continent}
                  value={formState.country}
                  onChange={handleInputChange}
                  formState={formState}
                />
              </div>
              {formState.continent === "NA" && (
                <div className="custom-select-container">
                  <SelectState
                    value={formState.state}
                    onChange={handleInputChange}
                    formState={formState}
                  />
                </div>
              )}
            </div>
            <div className="sex">
              <h3>Sex at birth</h3>
              <SexSelect value={formState.sex} onChange={handleInputChange} />
              <div className="sex_options">
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    name="sex"
                    value="Male"
                    id="male"
                    checked={formState.sex === "Male"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    value="Female"
                    name="sex"
                    id="female"
                    checked={formState.sex === "Female"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="female">Female</label>
                </div>
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    value="Other"
                    id="other"
                    name="sex"
                    checked={formState.sex === "Other"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="other">Other</label>
                </div>
              </div>
            </div>
            {/* <div>
              {formState.sex === "Female" && (
                <div className="sex">
                  <h3>
                    Pregnant?

                    <span className="pregnant_details">
                      (we ask because there are special testing to help during
                      this time)
                    </span>

                  </h3>
                  <div className="sex_options">
                    <div className="radio-wrapper">
                      <input
                        type="radio"
                        name="pregnant"
                        value="Yes"
                        id="pregnant"
                        checked={formState.pregnant === "Yes"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="pregnant">Yes</label>
                    </div>
                    <div className="radio-wrapper">
                      <input
                        type="radio"
                        value="No"
                        name="pregnant"
                        id="not-pregnant"
                        checked={formState.pregnant === "No"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="not-pregnant">No</label>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
            {formState.continent === "EU" && (
              <span className="error">
                Unfortunately we cannot yet handle information for individuals
                in Europe due to GDPR.{" "}
              </span>
            )}
            <div className="button">
              <Button
                type="primary"
                disabled={!isPersonalDetailsFormCompleted(formState)}
                onClick={async (e) => {
                  const result = await updatePersonalDetails(e);
                  if (result) return navigate("/wellness/welcome");
                  else {
                    // return navigate("/wellness/signup");
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </form>
        </>
      )}
      {isLoading && <Spinner />}
    </div>
  );
};

export default SignUpPersonalDetails;

import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/wellnessHome.scss";
import { ThemeContext } from "../contexts/ThemeContext";
import { useResponsive } from "../tools/device";
import USPTF from "../images/uspreventativetaskforce.png";
import AAP from "../images/AAP.png";
import ACOG from "../images/acoglogo.png";
import AACAP from "../images/AACAP.png";
import CHA from "../images/chalogo.svg";
import { AACAPIcon } from "../images/icons";
import Button from "../components/Button";

const RecommendedScreenings = ({ user }) => {
  const isMobile = useResponsive()?.isMobile;
  const navigate = useNavigate();

  return (
    <div>
      <div className="recommended_screenings_title">
        {isMobile ? (
          <div>Recommended by top health organizations</div>
        ) : (
          <div>
            Routine screenings recommended by <br /> the most important health
            organizations
          </div>
        )}
      </div>
      <div className="recommended_screenings">
        {/* <div> */}
        <img src={AAP} alt="AAP" className="AAP" />
        {/* </div> */}
        {/* <div> */}
        <img src={AACAP} alt="AACAP" className="AACAP" />
        {/* </div> */}
        {/* <div> */}
        <img src={USPTF} alt="USPTF" className="USPTF" />
        {/* </div> */}
        {/* <div> */}
        <img src={ACOG} alt="ACOG" className="ACOG" />
        {/* </div> */}
        {/* <div>
        <img src={CHA} alt="CHA" className="CHA" />
      </div> */}
      </div>
      <div className="banner">
        {user ? (
          <Button
            type="primary"
            onClick={() => {
              navigate("/wellness/dashboard");
            }}
            scrollToTop={true}
          >
            Go to dashboard
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              navigate("/wellness/signup");
            }}
          >
            Take the Quiz
          </Button>
        )}
      </div>
    </div>
  );
};

export default RecommendedScreenings;

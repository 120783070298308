import React from "react";

export function NeutralFaceFilledIcon({
  size = 1, // or any default size of your choice
}) {
  return (
    <div
      style={{
        maxWidth: "2.25rem",
        maxHeight: "2.25rem",
        overflow: "hidden",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 122.88 122.88"
      >
        <g>
          {/* Outer circle */}
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#FBD433",
            }}
            d="M45.54,2.11c32.77-8.78,66.45,10.67,75.23,43.43c8.78,32.77-10.67,66.45-43.43,75.23 c-32.77,8.78-66.45-10.67-75.23-43.43C-6.67,44.57,12.77,10.89,45.54,2.11L45.54,2.11z"
          />
          {/* Left eye */}
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#141518",
            }}
            d="M45.78,32.27c4.3,0,7.78,5.05,7.78,11.27c0,6.22-3.48,11.27-7.78,11.27c-4.3,0-7.78-5.05-7.78-11.27 C38,37.32,41.48,32.27,45.78,32.27L45.78,32.27z"
          />
          {/* Right eye */}
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#141518",
            }}
            d="M77.1,32.27c4.3,0,7.78,5.05,7.78,11.27c0,6.22-3.48,11.27-7.78,11.27 c-4.3,0-7.78-5.05-7.78-11.27C69.31,37.32,72.8,32.27,77.1,32.27L77.1,32.27z"
          />
          {/* Neutral mouth */}
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#141518",
            }}
            d="M38,80h46v4H38V80z"
          />
        </g>
      </svg>
    </div>
  );
}

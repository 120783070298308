import { SadFaceIcon } from "../../images/SadFaceIcon";
import { SadFaceFilledIcon } from "../../images/SadFaceFilledIcon";
import "./QuizFormInput.css";
import { SmileyFaceFilledIcon } from "../../images/SmileyFaceFilledIcon";
import { NeutralFaceFilledIcon } from "../../images/NeutralFaceFilledIcon";
import { UnhappyFaceFilledIcon } from "../../images/UnhappyFaceFilledIcon";
import { SuperSmileyFaceFilledIcon } from "../../images/SuperSmileyFaceFilledIcon";

const QuizInput = ({
  label,
  secondLabel = "",
  name,
  register,
  options,
  questions,
  onClick,
  setValue,
  higherScoreWorse,
}) => {
  // const className = "quiz";
  const className = "QuizFormInput";

  const letteredOrder = ["A", "B", "C", "D", "E", "F", "G"];
  const smiles = [
    <SuperSmileyFaceFilledIcon />,
    <SmileyFaceFilledIcon />,
    <NeutralFaceFilledIcon />,
    <UnhappyFaceFilledIcon />,
    <SadFaceFilledIcon />,
  ];

  return (
    <div className={className}>
      {console.log(higherScoreWorse)}
      <div className={`${className}-optionsContainer`}>
        {options?.map((option, index) => {
          return (
            <div
              className={`${className}-div`}
              key={index}
              name={name}
              id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
            >
              <input
                id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
                value={option}
                type="radio"
                {...register(name, { required: true })}
                className={`${className}-radio`}
                onClick={(e) => onClick(option, name, e)}
              />
              {
                <label
                  className={`${className}-label`}
                  onClick={(e) => onClick(option, name, e)}
                  role="radio"
                  htmlFor={`${name}_${option
                    .replace(/s+/gi, "_")
                    .toLowerCase()}`}
                >
                  {`${letteredOrder[index]}.`} &nbsp;&nbsp;{`${option}`}
                  &nbsp;&nbsp;&nbsp;
                  {smiles[index]}
                </label>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuizInput;

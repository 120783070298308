import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Legend,
  Tooltip,
} from "chart.js";
import { girlsHeightDataWHOFiveTo } from "./growthdata";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Legend,
  Tooltip
);

const GrowthChart = ({ userTestsData }) => {
  // State for gender, units (metric/imperial), and the expanded index
  const [gender, setGender] = useState("boy");
  const [units, setUnits] = useState("imperial");
  const [standard, setStandard] = useState("CDC"); // Toggle between CDC or WHO
  const [chartType, setChartType] = useState("Length vs Age"); // Chart type selection
  const [ageGroup, setAgeGroup] = useState("2-20 Years");

  // Sample data for CDC and WHO for both boys and girls
  const dataByGenderAndStandard = {
    CDC: {
      boy: {
        headSize: [
          40, 41.5, 42.8, 44.0, 45.1, 46.0, 46.8, 47.4, 48.0, 48.5, 49.0, 49.3,
        ],
        meanHeight: [
          88.0, 92.5, 97.0, 101.2, 105.1, 108.8, 112.3, 115.7, 119.0, 122.2,
          125.4, 128.5,
        ],
        meanWeight: [
          12.0, 13.4, 14.9, 16.4, 17.9, 19.3, 20.7, 22.0, 23.3, 24.5, 25.8,
          27.0,
        ],
      },
      girl: {
        headSize: [
          39.5, 41.0, 42.3, 43.5, 44.6, 45.5, 46.3, 46.9, 47.5, 48.0, 48.4,
          48.7,
        ],
        meanHeight: [
          86.0, 90.2, 94.3, 98.2, 102.0, 105.6, 109.0, 112.3, 115.5, 118.6,
          121.6, 124.6,
        ],
        meanWeight: [
          11.5, 12.9, 14.4, 15.8, 17.1, 18.4, 19.7, 20.9, 22.1, 23.3, 24.5,
          25.6,
        ],
      },
    },
    WHO: {
      boy: {
        headSize: [
          40.2, 41.7, 43.0, 44.2, 45.3, 46.2, 47.0, 47.6, 48.2, 48.7, 49.2,
          49.5,
        ],
        meanHeight: [
          87.0, 91.5, 96.0, 100.2, 104.1, 107.8, 111.3, 114.7, 118.0, 121.2,
          124.4, 127.5,
        ],
        meanWeight: [
          11.8, 13.2, 14.7, 16.2, 17.7, 19.1, 20.5, 21.8, 23.1, 24.3, 25.6,
          26.8,
        ],
      },
      girl: {
        headSize: [
          39.7, 41.2, 42.5, 43.7, 44.8, 45.7, 46.5, 47.1, 47.7, 48.2, 48.6,
          48.9,
        ],
        meanHeight: girlsHeightDataWHOFiveTo.map((age) => age.M),
        meanWeight: [
          11.3, 12.7, 14.2, 15.6, 16.9, 18.2, 19.5, 20.7, 21.9, 23.1, 24.2,
          25.3,
        ],
      },
    },
  };

  const { meanHeight, meanWeight, headSize } =
    dataByGenderAndStandard[standard][gender];

  const chartData = {
    "Length vs Age": meanHeight,
    "Weight vs Age": meanWeight,
    "Head Circumference vs Age": headSize,
  };

  const dataSets = Object.keys(chartData).reduce((acc, type) => {
    acc[type] = {
      labels: Array.from({ length: chartData[type].length }, (_, i) => i + 2),
      datasets: [
        {
          label: `Mean (50th Percentile)`,
          data:
            units === "imperial"
              ? chartData[type].map((value) =>
                  chartType === "Weight vs Age"
                    ? value * 2.20462
                    : value * 0.393701
                )
              : chartData[type],
          borderColor: "orange",
          fill: false,
        },
      ],
    };
    return acc;
  }, {});

  // 1. Add the following state variables to track user input
  const [userHeight, setUserHeight] = useState(
    userTestsData?.userHeight ? userTestsData.userHeight : ""
  );
  const [userWeight, setUserWeight] = useState(
    userTestsData?.userWeight ? userTestsData.userWeight : ""
  );
  const [userHeadSize, setUserHeadSize] = useState(
    userTestsData?.userHeadSize ? userTestsData.userHeadSize : ""
  );
  const [userAge, setUserAge] = useState(
    userTestsData?.userAge ? userTestsData.userAge : ""
  );

  // 2. Add handlers for the input changes
  const handleUserHeightChange = (event) => setUserHeight(event.target.value);
  const handleUserWeightChange = (event) => setUserWeight(event.target.value);
  const handleUserHeadSizeChange = (event) =>
    setUserHeadSize(event.target.value);
  const handleUserAgeChange = (event) => setUserAge(event.target.value);

  const handleToggle = (event) => setStandard(event.target.value);
  const handleGenderChange = (event) => setGender(event.target.value);
  const handleUnitsChange = (event) => setUnits(event.target.value);
  const handleChartTypeChange = (event) => setChartType(event.target.value);

  // 3. Add an additional dataset for the user input marker to the chart
  const userDataPoint = {
    x: parseFloat(userAge), // Convert age to a number
    y:
      chartType === "Length vs Age"
        ? parseFloat(units === "imperial" ? userHeight : userHeight / 2.54) // Convert height to inches if metric
        : chartType === "Weight vs Age"
        ? parseFloat(units === "imperial" ? userWeight : userWeight / 0.453592) // Convert weight to lbs if metric
        : parseFloat(units === "imperial" ? userHeadSize : userHeadSize / 2.54), // Convert head size to inches if metric
  };

  const userMarkerDataset = {
    label: "You",
    data: [userDataPoint],
    borderColor: "blue",
    backgroundColor: "blue",
    pointBackgroundColor: "red",
    pointBorderWidth: 4,
    pointRadius: 6,
    showLine: false,
  };

  // Add `userMarkerDataset` to `dataSets`
  const chartTypeLabelsArray = dataSets[chartType].labels;
  if (
    userDataPoint.x >= chartTypeLabelsArray[0] &&
    userDataPoint.x <= chartTypeLabelsArray[chartTypeLabelsArray.length - 1]
    // userDataPoint.y >= Math.min(...chartData[chartType]) &&
    // userDataPoint.y <= Math.max(...chartData[chartType])
  ) {
    // Add the user marker only if within the valid range
    dataSets[chartType].datasets.push({
      label: "You",
      data: [userDataPoint],
      borderColor: "blue",
      backgroundColor: "blue",
      pointBackgroundColor: "red",
      pointBorderWidth: 6,
      pointRadius: 8,
      showLine: false,
    });
  }

  return (
    <div style={{ maxWidth: "900px", margin: "0 auto" }}>
      <h2>Interactive Growth Chart with CDC/WHO Standards</h2>
      {/* New User Input Fields */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <div>
          <label>Age (Years):</label>
          <input
            type="number"
            value={userAge}
            min="2"
            max="20"
            onChange={handleUserAgeChange}
          />
        </div>
        <div>
          <label>Height ({units === "imperial" ? "inches" : "cm"}):</label>
          <input
            type="number"
            value={userHeight}
            onChange={handleUserHeightChange}
          />
        </div>
        <div>
          <label>Weight ({units === "imperial" ? "lbs" : "kg"}):</label>
          <input
            type="number"
            value={userWeight}
            onChange={handleUserWeightChange}
          />
        </div>
        <div>
          <label>
            Head Circumference ({units === "imperial" ? "inches" : "cm"}):
          </label>
          <input
            type="number"
            value={userHeadSize}
            onChange={handleUserHeadSizeChange}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <div>
          <label>Standard:</label>
          <select value={standard} onChange={handleToggle}>
            <option value="CDC">CDC</option>
            <option value="WHO">WHO</option>
          </select>
        </div>
        <div>
          <label>Gender:</label>
          <select value={gender} onChange={handleGenderChange}>
            <option value="boy">Boy</option>
            <option value="girl">Girl</option>
          </select>
        </div>
        <div>
          <label>Units:</label>
          <select value={units} onChange={handleUnitsChange}>
            <option value="metric">Metric (cm/kg)</option>
            <option value="imperial">Imperial (in/lbs)</option>
          </select>
        </div>
        <div>
          <label>Chart Type:</label>
          <select value={chartType} onChange={handleChartTypeChange}>
            <option value="Length vs Age">Length vs Age</option>
            <option value="Weight vs Age">Weight vs Age</option>
            <option value="Head Circumference vs Age">
              Head Circumference vs Age
            </option>
          </select>
        </div>
      </div>

      <Line
        data={dataSets[chartType]}
        options={{
          responsive: true,
          plugins: {
            legend: { position: "top" },
            title: {
              display: true,
              text: `${chartType} (${units === "metric" ? "cm/kg" : "in/lbs"})`,
            },
          },
          scales: {
            x: { title: { display: true, text: "Age (Years)" } },
            y: {
              title: {
                display: true,
                text: chartType.includes("Weight")
                  ? units === "metric"
                    ? "kg"
                    : "lbs"
                  : units === "metric"
                  ? "cm"
                  : "inches",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default GrowthChart;

// components/Modal.js
import React from "react";
import "./ReferralModal.css";

const ReferralModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="referral-modal-overlay" onClick={onClose}>
      <div
        className="referral-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default ReferralModal;

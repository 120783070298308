import React from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { FiMail, FiMapPin, FiShare2 } from "react-icons/fi";
import Button from "../../Button";

const TherapistDetail = ({ therapist }) => {
  return (
    <div style={{ display: "flex", padding: "2rem" }}>
      <Row>
        {/* Left Section: Image and Main Details */}
        <Col md={8} style={{ paddingRight: "30px" }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <img
              src={therapist?.imgSrc}
              alt={therapist.name}
              style={{
                width: "140px",
                height: "140px",
                borderRadius: "8px",
                marginRight: "20px",
              }}
            />
            <div>
              <h3
                style={{
                  fontSize: "1.75rem",
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
              >
                {therapist.name}
              </h3>
              <h5
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                {therapist.title}
              </h5>
              {therapist.verified && (
                <Badge
                  pill
                  bg="success"
                  style={{ marginRight: "10px", fontSize: "0.85rem" }}
                >
                  Verified by Psychology Today
                </Badge>
              )}
              <Badge pill bg="info" style={{ fontSize: "0.85rem" }}>
                {therapist.endorsements} Endorsed
              </Badge>
            </div>
          </div>
          <p style={{ marginTop: "20px", fontSize: "1rem", lineHeight: "1.6" }}>
            {therapist.description}
          </p>
          <Card
            style={{
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f8f9fa",
              marginTop: "20px",
            }}
          >
            <p style={{ fontSize: "1rem", fontWeight: "600" }}>
              Call or email {therapist.name} now for a{" "}
              <span style={{ color: "#007bff" }}>
                free 15 minute consultation
              </span>{" "}
              - {therapist.phone}
            </p>
          </Card>
        </Col>

        {/* Right Sidebar: Contact & Practice Info */}
        <Col
          md={4}
          style={{ borderLeft: "1px solid #e9ecef", paddingLeft: "30px" }}
        >
          <div style={{ marginBottom: "30px" }}>
            <h6
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              Let's Connect
            </h6>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaPhoneAlt style={{ marginRight: "10px", fontSize: "1.2rem" }} />
              <span style={{ fontSize: "1rem" }}>{therapist.phone}</span>
            </div>
            <Button type="primary">Email me</Button>
          </div>

          <Card
            style={{
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f8f9fa",
            }}
          >
            <h6
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              My Practice at a Glance
            </h6>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiMapPin style={{ marginRight: "10px", fontSize: "1.2rem" }} />
              <span style={{ fontSize: "1rem" }}>{therapist.location}</span>
            </div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaPhoneAlt style={{ marginRight: "10px", fontSize: "1.2rem" }} />
              <span style={{ fontSize: "1rem" }}>{therapist.phone}</span>
            </div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiMail style={{ marginRight: "10px", fontSize: "1.2rem" }} />
              <Button
                type="nofill"
                style={{ padding: "0", fontSize: "1rem", fontWeight: "600" }}
              >
                Email me
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FiShare2 style={{ marginRight: "10px", fontSize: "1.2rem" }} />
              <Button
                variant="nofill"
                style={{ padding: "0", fontSize: "1rem", fontWeight: "600" }}
              >
                Share
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TherapistDetail;

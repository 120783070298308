import React, { useState } from "react";
import { isFormCompleted, signIn } from "../utils";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import "../styles/signUpWellness.scss";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
const SignUpWellness = () => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    source: "Micah",
    socialMedia: "",
    doctorCode: "",
    otherInfo: "",
    friend: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location?.state || { from: "unknown" };

  const handleSignIn = async (e) => {
    e.preventDefault();

    const result = await signIn(
      formState.firstName,
      formState.lastName,
      formState.email,
      formState.password,
      formState.confirmPassword,
      formState.source,
      formState.socialMedia,
      formState.doctorCode,
      formState.otherInfo,
      formState.friend,
      setIsLoading,
      setError,
      userId
    );
    setIsFormSubmitted(true);
    return result;
  };

  const clearOutFields = (event) => {
    const { name, value } = event.target;
    if (name !== "source") return;
    if (value === "Micah") {
      setFormState((prevState) => ({
        ...prevState,
        doctorCode: "",
        friend: "",
        otherInfo: "",
        socialMedia: "",
      }));
    }
    if (value === "Doctor") {
      setFormState((prevState) => ({
        ...prevState,
        friend: "",
        otherInfo: "",
        socialMedia: "",
      }));
    }
    if (value === "SocialMedia") {
      setFormState((prevState) => ({
        ...prevState,
        doctorCode: "",
        friend: "",
        otherInfo: "",
      }));
    }
    if (value === "Friend") {
      setFormState((prevState) => ({
        ...prevState,
        doctorCode: "",
        otherInfo: "",
        socialMedia: "",
      }));
    }
    if (value === "Other") {
      setFormState((prevState) => ({
        ...prevState,
        doctorCode: "",
        friend: "",
        socialMedia: "",
      }));
    }
  };

  const validateFormState = (e) => {
    let { name, value } = e.target;
    console.log("error", error, "name", name, value, value?.length);
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please enter a first name.";
          }
          break;

        case "lastName":
          if (!value) {
            stateObj[name] = "Please enter last name.";
          }
          break;

        case "email":
          if (!value) {
            stateObj[name] = "Please enter a valid email.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter password.";
          } else if (value.length < 6) {
            stateObj[name] = "Password must be at least 6 characters.";
          } else if (
            formState.confirmPassword &&
            value !== formState.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and confirm password do not match.";
          } else {
            stateObj["confirmPassword"] = formState.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter confirm password.";
          } else if (formState.password && value !== formState.password) {
            stateObj[name] = "Password and confirm password do not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    clearOutFields(event);

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateFormState(event);
  };

  return (
    <div className="signup-form-container">
      {!isLoading && (
        <>
          {console.log("location", location, "from", from)}
          <h1>Create Account</h1>
          <div className="no-signup">
            <span className="question">
              Already have an account,
              <Link to="/wellness/login">
                <b>Login</b>
              </Link>
            </span>
            <span className="question">
              Feel free to skip the signup and go straight to the app.
              <br />
              (Note: results of scores won't be saved.)
              <br />
              <Link
                to={
                  from === "unknown"
                    ? "/wellness/check-in"
                    : "/wellness/check-in-youth"
                }
                state={{ from }}
              >
                <b className="bold">Skip signup</b>
              </Link>
            </span>
          </div>
          <br />
          <form>
            {/* Skip sign up*/}

            <div className="names">
              <input
                name="firstName"
                value={formState.firstName}
                onChange={handleInputChange}
                onBlur={validateFormState}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lastName"
                type="text"
                value={formState.last}
                onChange={handleInputChange}
                onBlur={validateFormState}
                placeholder="Last Name"
              />
            </div>
            <input
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              onBlur={validateFormState}
              type="email"
              placeholder="Email"
            />
            <input
              type="password"
              name="password"
              placeholder="Enter Password"
              value={formState.password}
              onChange={handleInputChange}
              onBlur={validateFormState}
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Please Confirm Password"
              value={formState.confirmPassword}
              onChange={handleInputChange}
              onBlur={validateFormState}
            />
            <div className="hear_about_us">
              <h3>How did you hear about us?</h3>
              <div className="hear_about_us_options">
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    name="source"
                    value="Micah"
                    id="Micah"
                    checked={formState.source === "Micah"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="Micah">Micah</label>
                </div>
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    name="source"
                    value="Doctor"
                    id="Doctor"
                    checked={formState.source === "Doctor"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="Doctor">Doctor</label>
                </div>
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    name="source"
                    value="SocialMedia"
                    id="SocialMedia"
                    checked={formState.source === "SocialMedia"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="SocialMedia">Social Media</label>
                </div>
                <div className="hear_about_us">
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      name="source"
                      value="Friend"
                      id="friend"
                      checked={formState.source === "Friend"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="friend">Friend</label>
                  </div>
                </div>
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    value="Other"
                    id="other"
                    name="source"
                    checked={formState.source === "Other"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="other">Other</label>
                </div>
              </div>
            </div>
            <div>
              {formState.source === "Other" && (
                <input
                  type="text"
                  name="otherInfo"
                  placeholder="Where from?"
                  value={formState.otherInfo}
                  onChange={handleInputChange}
                />
              )}
              {formState.source === "Friend" && (
                <div>
                  <input
                    type="text"
                    name="friend"
                    placeholder="Friend's Name"
                    value={formState.friend}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {formState.source === "SocialMedia" && (
                <div className="hear_about_us_options_detail">
                  <div className="radio-wrapper-secondary">
                    <input
                      type="radio"
                      name="socialMedia"
                      value="Facebook"
                      id="facebook"
                      checked={formState.socialMedia === "Facebook"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="facebook">Facebook</label>
                  </div>
                  <div className="radio-wrapper-secondary">
                    <input
                      type="radio"
                      value="Reddit"
                      id="reddit"
                      name="socialMedia"
                      checked={formState.socialMedia === "Reddit"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="reddit">Reddit</label>
                  </div>
                  <div className="radio-wrapper-secondary">
                    <input
                      type="radio"
                      value="Instagram"
                      id="instagram"
                      name="socialMedia"
                      checked={formState.socialMedia === "Instagram"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="instagram">Instagram</label>
                  </div>
                </div>
              )}
              {formState.source === "Doctor" && (
                <div>
                  <input
                    type="text"
                    name="doctorCode"
                    placeholder="Doctor Code"
                    value={formState.doctorCode}
                    onChange={handleInputChange}
                  />
                  {/*
                  Doctor State Code: 001 -- 050
                  Doctor Code: 000-000-0000
                  Specialty Code: 000-999
                  <input
                    type="text"
                    name="doctorCode"
                    placeholder="Doctor Code"
                    value={formState.doctorCode}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    name="doctorCode"
                    placeholder="Doctor Code"
                    value={formState.doctorCode}
                    onChange={handleInputChange}
                  />
                  */}
                </div>
              )}
            </div>
            {error.firstName && (
              <span className="error">{error.firstName}</span>
            )}
            {error.lastName && <span className="error">{error.lastName}</span>}
            {error.email && <span className="error">{error.email}</span>}
            {error.password && <span className="error">{error.password}</span>}
            {error.confirmPassword && (
              <span className="error">{error.confirmPassword}</span>
            )}
            {isFormSubmitted && error && (
              <span className="error">{error.signUpError}</span>
            )}
            <div className="signup">
              {console.log("formState", !isFormCompleted(formState))}
              <Button
                type="primary"
                disabled={!isFormCompleted(formState)}
                onClick={async (e) => {
                  const result = await handleSignIn(e);
                  if (result)
                    return navigate("/wellness/signup/personal-details");
                  else {
                    // return navigate("/wellness/signup");
                  }
                }}
              >
                Sign Up
              </Button>
            </div>
          </form>
        </>
      )}
      {isLoading && <Spinner />}
    </div>
  );
};

export default SignUpWellness;

import React, { useState } from "react";
import "./FourToggleSwitch.css";

const FourToggleSwitch = ({ label, value, onChange, options }) => {
  const [active, setActive] = useState(value);

  const handleToggle = (selected) => {
    setActive(selected);
    onChange(selected);
  };

  return (
    <div className="four-toggle-switch">
      <button
        className={`four-toggle-button ${
          active === options[0] ? "active" : ""
        }`}
        onClick={() => handleToggle(options[0])}
      >
        {options[0]}
      </button>
      <button
        className={`four-toggle-button ${
          active === options[1] ? "active" : ""
        }`}
        onClick={() => handleToggle(options[1])}
      >
        {options[1]}
      </button>
      <button
        className={`four-toggle-button ${
          active === options[2] ? "active" : ""
        }`}
        onClick={() => handleToggle(options[2])}
      >
        {options[2]}
      </button>
      <button
        className={`four-toggle-button ${
          active === options[3] ? "active" : ""
        }`}
        onClick={() => handleToggle(options[3])}
      >
        {options[3]}
      </button>
    </div>
  );
};

export default FourToggleSwitch;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./Quiz.css";
import "./QuizForm.css";
import useAnalytics from "../../hooks/useAnalytics";
import QuizInput from "./QuizInput";
import Button from "../Button";

const QuizForm = ({
  questions,
  formState,
  setFormState,
  selectedFormData,
  handleSubmitForm,
  formStyle,
}) => {
  const className = "QuizForm";
  const { trackFormEvent } = useAnalytics();

  const formHook = useForm();
  const { register, handleSubmit, setValue, watch } = formHook;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const question = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const onSubmit = (data) => {
    console.log(data);
    // Add any additional submit logic here
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
  };

  const handleFormChange = () => {
    const values = formHook.getValues();
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
    setFormState({
      values,
      isValid: !Object.values(values).includes(null),
    });
  };

  const handleNext = (option, name, e) => {
    e.stopPropagation();
    if (e.target !== e.currentTarget) return;
    if (e.target.tagName !== "INPUT") return;
    console.log("target", e, e.target);

    if (isLastQuestion) return;
    else setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
  };

  const handlePrev = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
  };

  const length = selectedFormData.questions.length;
  const { scaleTwoStart = length - 1, scaleTwo } = selectedFormData;
  const doesScaleScaleTwoExist = !!scaleTwo;
  const chooseOptions = (question, index) => {
    const scale = selectedFormData.scale || question.answers;

    if (!doesScaleScaleTwoExist) return scale;

    const scaletoUse =
      index + 1 < scaleTwoStart ? scale : selectedFormData.scaleTwo;
    return scaletoUse;
  };

  const titleParts = (index) => {
    if (selectedFormData.parts[0].startingPoint === index)
      return <h2>{selectedFormData.parts[0].name}</h2>;
    if (selectedFormData.parts[1].startingPoint === index)
      return <h2>{selectedFormData.parts[1].name}</h2>;
  };

  if (!question) return;

  const isFormComplete = () => {
    const values = formHook.getValues();
    return Object.values(values).length === questions.length;
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
        <div
          key={`question_${question.name}`}
          className={`${className}-container`}
        >
          {selectedFormData.moreThanOnePart
            ? titleParts(currentQuestionIndex)
            : ""}
          <div className={`${className}-question`}>{question.question}</div>
          {question.description && (
            <div className={`${className}-description`}>
              {question.description || ""}
            </div>
          )}
          <QuizInput
            options={chooseOptions(question, currentQuestionIndex)}
            onClick={handleNext}
            register={register}
            name={question.name}
            setValue={setValue}
            label={`${currentQuestionIndex + 1}. ${question.question}`}
            secondLabel={question.description || ""}
            higherScoreWorse={selectedFormData.higherScoreWorse}
          />
        </div>
        <div className={`${className}-button-container`}>
          {currentQuestionIndex > 0 && (
            <Button type="tertiary" onClick={handlePrev}>
              Previous
            </Button>
          )}
          {currentQuestionIndex === 0 && (
            <Button type="tertiary" onClick={handlePrev}>
              Back
            </Button>
          )}
          {isFormComplete() && (
            <Button type="secondary" onClick={handleSubmitForm}>
              Submit
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default QuizForm;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import Logo from "../images/Logo.svg";
// import SelfLogo from "../images/Self.svg";
// import { FaGlobe } from "react-icons/fa";
import { NewLogo } from "../images/NewLogo";
import "../styles/mobileNavbar.scss";
// import { logout } from "../utils";
import { auth } from "../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import { ProfileIcon } from "../images/ProfileIcon";

const MobileNavbar = () => {
  const [authUser, setAuthUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [isWellness, setIsWellness] = useState(true);

  useEffect(() => {
    setIsWellness(true);
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      }
      if (!user) {
        setAuthUser(null);
      }
    });
  }, []);

  return (
    <div className={`mobile_navbar ${isWellness ? "wellness" : ""}`}>
      <div className={`navbar_header ${isWellness ? "wellness" : ""}`}>
        <Link to="/wellness">
          <NewLogo />
        </Link>

        {!open && (
          <div className="hamburger" onClick={() => setOpen(!open)}>
            <div className={`line ${isWellness ? "wellness-line" : ""}`}></div>
            <div className={`line ${isWellness ? "wellness-line" : ""}`}></div>
            <div className={`line ${isWellness ? "wellness-line" : ""}`}></div>
          </div>
        )}
        {open && (
          <div
            className={`close ${isWellness ? "wellness-close" : ""}`}
            onClick={() => setOpen(!open)}
          >
            X
          </div>
        )}
      </div>
      {open && (
        <nav>
          <ul>
            {!isWellness && (
              <div>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/comingsoon"}
                  >
                    Coming Soon
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/personal"}
                  >
                    Personal
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/relationship"}
                  >
                    Relationship
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/career"}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/about"}
                  >
                    About
                  </Link>
                </li>
              </div>
            )}

            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setOpen(false);
                }}
                to={"/wellness/find-care"}
              >
                Find Care
              </Link>
            </li>
            {authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  to={
                    isWellness ? "/wellness/dashboard" : "/discover/dashboard"
                  }
                >
                  Dashboard
                </Link>
              </li>
            )}
            {isWellness && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  to={"/wellness/about"}
                >
                  About (coming soon...)
                </Link>
              </li>
            )}
            {!authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  className="signUp"
                  to={isWellness ? "/wellness/signup" : "/discover/dashboard"}
                >
                  Sign Up
                </Link>
              </li>
            )}
            {!authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  className="login"
                  to={isWellness ? "/wellness/login" : "/discover/login"}
                >
                  Login
                </Link>
              </li>
            )}
            {authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  to="/wellness/account"
                >
                  <ProfileIcon />
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileNavbar;

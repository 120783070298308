import React from "react";
import HomePageScreeningSection from "./HomePageScreeningSection";
import HomePageDataPrivacySection from "./HomePageDataPrivacySection";
import HomePageChatSection from "./HomePageChatSection";
import HomePageInsightsSection from "./HomePageInsightsSection";
import "../../../styles/wellnessHome.scss";
import "./QuadSection.scss";
import RecommendedScreenings from "../../RecommendedScreenings";

const QuadSection = ({ isMobile }) => {
  return (
    <div className="quad-section">
      {isMobile && (
        <>
          <div className="row">
            <div className="home-page-section">
              <HomePageScreeningSection />
            </div>
            <div className="home-page-section">
              <RecommendedScreenings />
            </div>
          </div>
          <div className="row">
            <div className="home-page-section">
              <HomePageDataPrivacySection />
            </div>
            <div className="home-page-section">
              <HomePageChatSection />
            </div>
          </div>
        </>
      )}
      {!isMobile && (
        <>
          <div className="row">
            <div className="home-page-section">
              <HomePageDataPrivacySection />
            </div>
            <div className="home-page-section">
              <HomePageScreeningSection />
            </div>
          </div>
          <div className="row">
            <div className="home-page-section">
              <HomePageChatSection />
            </div>
            <div className="home-page-section">
              <HomePageInsightsSection />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuadSection;

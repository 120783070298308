import { useEffect } from "react";

const ProviderTallyPage = ({ tallyId }) => {
  useEffect(() => {
    console.log("here", tallyId);
    // Dynamically load the Tally widget script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Load all Tally embeds on the page
      if (window.Tally) {
        window.Tally.loadEmbeds();
      }
    };

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        margin: "0",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {console.log("here", tallyId)}
      <iframe
        data-tally-src={`https://tally.so/r/${tallyId}`}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="SelfCare Navigation Form for Blueberry"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          border: "0",
        }}
      ></iframe>
    </div>
  );
};
export default ProviderTallyPage;

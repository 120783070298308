import React from "react";
import "../styles/WellnessHomeStats.scss";
import { SeeSaw } from "../images/seesaw";

const WellnessHomeStats = () => {
  return (
    <section className="stats-section">
      <div className="stats-header">
        <h1 className="redefine">
          Early detection leads to early intervention
        </h1>
      </div>
      <div className="stats-container">
        <div className="stat">
          <h3>60%</h3>
          <p>of individuals go undiagnosed</p>
          <small>
            That's right, and it rises to 75% in POC communities. With Self, you
            won’t become part of these statistics.
          </small>
        </div>
        <div className="stat">
          <h3>25%</h3>
          <div>
            <p>of the population has a mental health condition</p>
            <small>
              Start your journey to mental wellness now.
            </small> <br /> &nbsp;
          </div>
        </div>
        <div className="stat">
          <h3>11 years</h3>
          <p>average time from symptoms to treatment</p>
          <small>Don't go a decade through invisible pain.</small> <br /> &nbsp;
        </div>
      </div>
    </section>
  );
};

export default WellnessHomeStats;

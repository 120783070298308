import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { db, auth } from "../../firebase.config";
import "../../styles/wellnessDashboard.scss";

import PeriodTracker from "../PeriodTracker";
import { wasTestTakenInPastMonth } from "../../tools/timeTools";
import PhysicalCheckIn from "./PhysicalCheckin";
import VaccinationTracker from "./Vaccinations/Vaccinations";
import HeightWeightChart from "./GrowthCharts";

const PhysicalHealthDashboard = ({
  userTestsData,
  isUserFemale,
  isUserAdult,
  isUserPregnant,
}) => {
  const [userData, setUserData] = useState();
  const [checkinData, setCheckinData] = useState();
  const [personalityTestsData, setPersonalityTestsData] = useState();
  const [user, error] = useAuthState(auth);
  const [wellnessPercentage, setWellnessPercentage] = useState(0);
  const [wellnessFormsCompleted, setWellnessFormsCompleted] = useState([]);
  // States to manage the visibility of the sections
  const [isMentalHealthVisible, setMentalHealthVisible] = useState(true);
  const [isPhysicalHealthVisible, setPhysicalHealthVisible] = useState(true);
  const [isPeriodTrackingVisible, setPeriodTrackingVisible] = useState(false);
  const [isPersonalityVisible, setPersonalityVisible] = useState(false);

  const navigate = useNavigate();

  const getUserData = async (user) => {
    if (!user) return;

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserData(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  return (
    // <PhysicalCheckIn />
    <>
      {/* <HeightWeightChart userTestsData={userTestsData} /> */}
      {/* <VaccinationTracker userTestsData={userTestsData} /> */}
    </>
  );
};
export default PhysicalHealthDashboard;

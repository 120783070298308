import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore"; // Ensure you import these
import { db } from "../../firebase.config"; // Make sure db is imported from your Firebase config
import "./EditAccountProfileModal.scss"; // Your custom modal styles
import Button from "../../components/Button";
import { SelectCountries } from "../../components/Countries";
import { AgeSelect } from "../../components/Age";
import { SexSelect } from "../../components/Sex";
import { GenderSelect } from "../../components/Gender";
import { SelectInsurance } from "../../components/Insurances";
import { SelectState } from "../../components/States";

const EditProfileModal = ({ user, profile, onClose, onUpdate }) => {
  const [editedProfile, setEditedProfile] = useState(profile);

  const fieldsToShow = [
    {
      group: true, // Indicates this is a group of fields
      fields: [
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
      ],
    },
    {
      group: true, // Indicates this is a group of fields
      fields: [
        { key: "sex", label: "Sex", type: "select", component: SexSelect },
        {
          key: "gender",
          label: "Gender",
          type: "select",
          component: GenderSelect,
        },
      ],
    },

    // { key: "email", label: "Email", type: "email" },
    { key: "age", label: "Age", type: "select", component: AgeSelect },
    {
      key: "country",
      label: "Country",
      type: "select",
      component: SelectCountries,
      additionalProps: {
        continent: editedProfile.continent, // Ensure your state has 'continent' for this to work
      },
    },
    {
      key: "state",
      label: "State",
      type: "select",
      component: SelectState,
      additionalProps: {
        continent: editedProfile.continent, // Ensure your state has 'continent' for this to work
      },
    },
    {
      key: "insurance",
      label: "Insurance",
      type: "select",
      component: SelectInsurance,
    },
    // Add other individual fields or groups as needed
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("e", e);
    e.preventDefault();

    if (!user) {
      console.error("No user authenticated");
      return;
    }

    // Reference to the user's document in Firestore
    const userProfileRef = doc(db, "users", user.uid);

    try {
      // Update the user's profile in Firestore using updateDoc
      await updateDoc(userProfileRef, editedProfile);
      console.log("Profile updated successfully:", editedProfile);
      if (onUpdate) {
        await onUpdate(); // Call the re-fetch profile function after update
      }
      onClose(); // Close the modal
    } catch (error) {
      // Handle any errors that occur during profile update
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="edit-account-profile-modal-backdrop">
      {console.log("user", user, "profile", profile, onClose, onUpdate)}
      <div className="edit-account-profile-modal">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>Edit Profile</h2>
        <form onSubmit={handleSubmit}>
          {/* Generate form fields based on profile object */}
          {fieldsToShow.map((field) =>
            field.group ? (
              <div
                key={field.fields.map((f) => f.key).join("-")}
                className="input-group-row"
              >
                {field.fields.map(
                  ({
                    key,
                    label,
                    type = "text",
                    component = null,
                    additionalProps = {},
                  }) => (
                    <div key={key} className="input-group">
                      <label htmlFor={key}>{label}</label>
                      {/* <input
                      id={key}
                      type={type}
                      name={key}
                      value={editedProfile[key] || ""}
                      onChange={handleChange}
                    /> */}
                      {type === "select" ? (
                        // Render select dropdown component
                        React.createElement(component, {
                          id: key,
                          name: key,
                          value: editedProfile[key] || "",
                          onChange: handleChange,
                          ...additionalProps, // Spread additional props if any
                        })
                      ) : (
                        // Render standard input
                        <input
                          id={key}
                          type={type || "text"}
                          name={key}
                          value={editedProfile[key] || ""}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div key={field.key} className="input-group">
                <label htmlFor={field.key}>{field.label}</label>
                {field.type === "select" ? (
                  // Render select dropdown component
                  React.createElement(field.component, {
                    id: field.key,
                    name: field.key,
                    value: editedProfile[field.key] || "",
                    onChange: handleChange,
                    ...field.additionalProps, // Spread additional props if any
                  })
                ) : (
                  // Render standard input
                  <input
                    id={field.key}
                    type={field.type || "text"}
                    name={field.key}
                    value={editedProfile[field.key] || ""}
                    onChange={handleChange}
                  />
                )}
              </div>
            )
          )}
          <div className="button-container">
            <Button type="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" buttonType="submit">
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfileModal;

/* Libraries */
import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { db, auth } from "../../firebase.config";
import { LockOutlined } from "@ant-design/icons/lib/icons";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

/* Styles / Components / Pages */
import "../../styles/wellnessDashboard.scss";
import formData from "../WellnessTest/formData";
import formCategories from "./WellnessDashboardTests/formCategories";
import { checkupData } from "../WellnessGettingStarted/checkinTestsData";
import personalityCategories from "./PersonalityDashboardTests/personalityCategories";
import { getPercentage } from "../../utils";
import WellnessDashboardTests from "./WellnessDashboardTests";
import PeriodTracker from "../PeriodTracker";
import { wasTestTakenInPastMonth } from "../../tools/timeTools";
import PhysicalHealthDashboard from "../PhysicalHealthDashboard/PhysicalHealthDashboard";
import WellnessDashboardHeader from "./WellnessDashboardHeader";

const WellnessDashboard = () => {
  const [userData, setUserData] = useState();
  const [userTestsData, setUserTestsData] = useState();
  const [checkinData, setCheckinData] = useState();
  const [personalityTestsData, setPersonalityTestsData] = useState();
  const [user, error] = useAuthState(auth);
  const [wellnessPercentage, setWellnessPercentage] = useState(0);
  const [wellnessFormsCompleted, setWellnessFormsCompleted] = useState([]);
  // States to manage the visibility of the sections
  const [isMentalHealthVisible, setMentalHealthVisible] = useState(true);
  const [isPhysicalHealthVisible, setPhysicalHealthVisible] = useState(true);
  const [isPeriodTrackingVisible, setPeriodTrackingVisible] = useState(false);
  const [isPersonalityVisible, setPersonalityVisible] = useState(false);

  const navigate = useNavigate();

  const getUserData = async (user) => {
    if (!user) return;

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserData(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const addWellnessCompletedForm = (newCompletedForm) => {
    setWellnessFormsCompleted((formsCompleted) => [
      ...formsCompleted,
      newCompletedForm,
    ]);
  };

  const HealthSummary = () => {
    //TODO: This needs to be fixed
    let value = 0;
    let totalNumberOfCompletedTests = 0;
    let averageScore = 0;
    if (userTestsData) {
      Object.keys(userTestsData).map((key) => {
        // If a higher score is worse, subtract from the total value.
        // If a higher score is better, add to the total value.
        if (formData[key].higherScoreWorse) {
          value -= userTestsData[key].score / formData[key].maxScore;
        } else {
          value += userTestsData[key].score / formData[key].maxScore;
        }
        // console.log("value", value);
        totalNumberOfCompletedTests++;
      });
    }
    if (!totalNumberOfCompletedTests)
      return (
        <div className={`score-level none`}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={"/wellness/check-in"}
            className="join_now"
          >
            <span className="score-description">Let's get you started</span>
          </Link>
        </div>
      );

    // Calculate the average score.
    averageScore = value / totalNumberOfCompletedTests;
    // console.log("value", value, "averageScore", averageScore);

    // Determine the health level based on the average score.
    let healthLevel = "excellent"; // Default to the highest level
    let healthClassName = "";

    if (averageScore < 0) {
      healthLevel = "Critical Support Needed";
      healthClassName = "critical";
    } else if (averageScore < 0.2) {
      healthLevel = "Excellent / Thriving";
      healthClassName = "excellent";
    } else if (averageScore < 0.33) {
      healthLevel = "Good / Healthy";
      healthClassName = "good";
    } else if (averageScore < 0.5) {
      healthLevel = "Moderate / Stable";
      healthClassName = "stable";
    } else if (averageScore < 0.6) {
      healthLevel = "Professional Help Recommended";
      healthClassName = "help-needed";
    } else {
      healthLevel = "Critical Support Needed";
      healthClassName = "critical";
    }
    // console.log("userTestsData", userTestsData);

    // Return the component with the score level and description.
    return (
      <div className={`score-level ${healthClassName}`}>
        <span className="score-description">{healthLevel}</span>
      </div>
    );
  };

  // Reusable component to render section header
  const SectionHeader = ({
    title,
    isVisible,
    toggleVisibility,
    mentalHealthScore,
  }) => (
    <div className="heading">
      <h2>{title}</h2>
      <div className="row">
        {/* {mentalHealthScore && (
          <div className="mental-health-score">{HealthSummary()}</div>
        )} */}
        <div className="toggle">
          <span onClick={toggleVisibility}>
            {isVisible ? <UpOutlined /> : <DownOutlined />}
          </span>
        </div>
      </div>
    </div>
  );

  const [popUpMessageLength, setPopUpMessageLength] = useState(0);

  useEffect(() => {
    const percentage = getPercentage(wellnessFormsCompleted, 9);
    setWellnessPercentage(percentage);
  }, [wellnessFormsCompleted]);

  useEffect(() => {
    if (user) {
      getUserData(user);
    }
  }, [user]);

  useEffect(() => {
    let pop_up_message_length = 0;
    // This is the part that gets an category array list from an formCategories array list.
    formCategories.forEach((category) => {
      category.tests.forEach((test) => {
        if (userTestsData && userTestsData[test.key]) {
          const createdDate = new Date(userTestsData[test.key].created);
          const dateOfLastTest = Date.now() - createdDate.getTime();
          const days = Math.floor(dateOfLastTest / (1000 * 60 * 60 * 24));
          if (days > 30) {
            pop_up_message_length += 1;
          }
        } else return;
      });
    });
    setPopUpMessageLength(pop_up_message_length);
  }, [userTestsData, formCategories]);

  useEffect(() => {
    if (user) {
      const testsRef = collection(db, "WellnessTestResults");
      const q = query(testsRef, where("user", "==", user.uid));
      const possibleTestKeys = formCategories.reduce((acc, category) => {
        return [...acc, ...category.tests.map((test) => test.key)];
      }, []);
      getDocs(q).then((res) => {
        const testsAsMap = {};
        const checkupTestsAsMap = {};
        res.forEach((doc) => {
          const testData = doc.data();
          if (
            (!testsAsMap[testData.formType] ||
              testsAsMap[testData.formType].created < testData.created) &&
            possibleTestKeys.includes(testData.formType)
          )
            testsAsMap[testData.formType] = {
              score: testData.score,
              created: testData.created,
            };
        });
        setUserTestsData(testsAsMap);

        checkupData.map((test) => {
          if (testsAsMap[test.key]) {
            const wasTestTakenLessThanOneMonthAgo = wasTestTakenInPastMonth(
              testsAsMap[test.key]
            );
            if (wasTestTakenLessThanOneMonthAgo) {
              checkupTestsAsMap[test.key] = testsAsMap[test.key];
            }
          }
        });
        setCheckinData(checkupTestsAsMap);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const testsRef = collection(db, "PersonalityTestResults");
      const q = query(testsRef, where("user", "==", user.uid));
      const possibleTestKeys = personalityCategories.reduce((acc, category) => {
        return [...acc, ...category.tests.map((test) => test.key)];
      }, []);
      getDocs(q).then((res) => {
        const testsAsMap = {};
        res.forEach((doc) => {
          const testData = doc.data();
          if (
            (!testsAsMap[testData.formType] ||
              testsAsMap[testData.formType].created < testData.created) &&
            possibleTestKeys.includes(testData.formType)
          )
            testsAsMap[testData.formType] = {
              score: testData.score,
              created: testData.created,
            };
        });
        setPersonalityTestsData(testsAsMap);
      });
    }
  }, [user]);

  // NEW - do we need user?
  // if (!userTestsData) return null;

  const totalNumberOfTests = formCategories.reduce(
    (acc, category) => acc + category.tests.length,
    0
  );
  const totalNumberOfCompletedTests = userTestsData
    ? Object.keys(userTestsData).length
    : "";
  const totalNumberOfCompletedCheckInTests = checkinData
    ? Object.keys(checkinData).length
    : "";

  const testProgress = Number(
    (totalNumberOfCompletedTests / totalNumberOfTests) * 100
  ).toFixed(2);

  const isUserFemale = userData && userData.sex === "Female";
  const isUserChild = userData && userData.age && userData.age < 18;
  const isUserPregnant = userData && !!userData.pregnant;

  // if (user) {
  return (
    <div className="profile">
      <WellnessDashboardHeader
        user={user}
        totalNumberOfCompletedTests={totalNumberOfCompletedTests}
        testProgress={testProgress}
      />
      {/* Mental Health Section */}
      <div className="tests-section">
        <SectionHeader
          title="Mental Health"
          isVisible={isMentalHealthVisible}
          toggleVisibility={() =>
            setMentalHealthVisible(!isMentalHealthVisible)
          }
          mentalHealthScore={true}
        />
        <p className="subtitle">A Measure of Your Emotional Wellbeing</p>
        {isMentalHealthVisible && (
          <>
            {/* {userData && <ProgressBar percentage={testProgress} />} */}
            <WellnessDashboardTests
              userTestsData={userTestsData}
              isUserFemale={isUserFemale}
              isUserChild={isUserChild}
              isUserPregnant={isUserPregnant}
            />
          </>
        )}
      </div>
      {/* Physical Health Section */}
      <div className="physical-health-container">
        <SectionHeader
          title="Physical Health"
          isVisible={isPhysicalHealthVisible}
          toggleVisibility={() =>
            setPhysicalHealthVisible(!isPhysicalHealthVisible)
          }
          PhysicalHealthScore={true}
        />
        <p className="subtitle">Coming Soon...</p>
        <p className="subtitle">A Measure of Your Physical Wellbeing</p>
        {isPhysicalHealthVisible && (
          <PhysicalHealthDashboard
            userTestsData={userTestsData}
            isUserFemale={isUserFemale}
            isUserChild={isUserChild}
            isUserPregnant={isUserPregnant}
          />
        )}
      </div>
      {/* Period Tracking Section */}
      <div className="tests-section">
        <SectionHeader
          title="Menstrual Health"
          isVisible={isPeriodTrackingVisible}
          toggleVisibility={() =>
            setPeriodTrackingVisible(!isPeriodTrackingVisible)
          }
        />
        <p className="subtitle">Coming Soon...</p>
        {/* <PeriodTracker /> */}
      </div>
      {isUserFemale && (
        <div className="tests-section">
          <SectionHeader
            title="Period Tracking"
            isVisible={isPeriodTrackingVisible}
            toggleVisibility={() =>
              setPeriodTrackingVisible(!isPeriodTrackingVisible)
            }
          />
          {isPeriodTrackingVisible && <PeriodTracker />}
        </div>
      )}
      {/* Personality Section */}
      {/*
        <div className="tests-section">
          <SectionHeader
            title="Personality"
            isVisible={isPersonalityVisible}
            toggleVisibility={() =>
              setPersonalityVisible(!isPersonalityVisible)
            }
          />
          {isPersonalityVisible && (
            <PersonalityDashboardTests
              personalityTestsData={personalityTestsData}
            />
          )}
        </div>
        */}
    </div>
  );
  // }
};
export default WellnessDashboard;

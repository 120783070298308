import React from "react";

export function SuperSmileyFaceFilledIcon({
  size = 1, // or any default size of your choice
}) {
  return (
    <div
      style={{
        maxWidth: "2.25rem",
        maxHeight: "2.25rem",
        opacity: "90%",
        overflow: "hidden",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 122.88 122.88"
      >
        <g>
          {/* Outer circle */}
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#FBD433",
            }}
            d="M45.54,2.11c32.77-8.78,66.45,10.67,75.23,43.43c8.78,32.77-10.67,66.45-43.43,75.23 c-32.77,8.78-66.45-10.67-75.23-43.43C-6.67,44.57,12.77,10.89,45.54,2.11L45.54,2.11z"
          />
          {/* Left eye */}
          <circle
            cx="42"
            cy="45"
            r="6"
            style={{
              fill: "#141518",
            }}
          />
          {/* Right eye */}
          <circle
            cx="80"
            cy="45"
            r="6"
            style={{
              fill: "#141518",
            }}
          />
          {/* Grinning mouth */}
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#141518",
            }}
            d="M38,80c15,8,30,8,46,0c1.3,0,2,1.1,1.5,2.2c-3.9,8.7-13.5,14.1-24.5,14.1S41.9,90.9,38,82.2 C37.5,81.1,38.7,80,38,80z"
          />
        </g>
      </svg>
    </div>
  );
}

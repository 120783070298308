import { useNavigate, useLocation } from "react-router-dom";
import "./Button.scss";
import useAnalytics from "../../hooks/useAnalytics";

const Button = ({
  children,
  type = "default",
  buttonType = "button",
  onClick,
  handleHover,
  handleMouseLeave,
  disabled,
  goto,
  externalUrl,
  gotoState,
  extraClassName,
  scrollToTop,
  analyticsContext,
}) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const location = useLocation(); // Capture current route information

  const className = ["Button", `Button-${type}`];
  if (disabled) className.push("Button-disabled");
  if (extraClassName) className.push(extraClassName);

  const ButtonAction = (event) => {
    // Append the current pathname to the event description or label
    const path = location.pathname;
    const label = `${
      analyticsContext?.description || "No description"
    } - ${path}`;
    trackEvent("click", "Button", label, `Clicked in ${path}`);

    if (externalUrl) {
      window.open(externalUrl, "_blank");
    } else if (goto) {
      if (scrollToTop) window.scrollTo(0, 0);
      navigate(goto, { state: gotoState });
    } else if (onClick) {
      if (scrollToTop) window.scrollTo(0, 0);
      return onClick(event);
    }
  };

  return (
    <button
      type={buttonType}
      disabled={disabled}
      className={className.join(" ")}
      onClick={ButtonAction}
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </button>
  );
};

export default Button;

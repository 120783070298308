import React, { useState } from "react";
import "./ReferralCard.css";
import Button from "../../Button";
import ReferralModal from "./ReferralModal";
import { MonitorIcon } from "../../../images/MonitorIcon";
import { InPersonIcon } from "../../../images/InPersonIcon";
import { MoneyIcon } from "../../../images/MoneyIcon";
import { addReferreredVisit } from "../../../utils";

const ReferralCard = ({ company }) => {
  const {
    companyName,
    price,
    priceDetail,
    availability,
    description,
    inPersonLocations,
    virtualLocations,
    logo,
    link,
    url,
  } = company;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCareModalOpen, setCareModalOpen] = useState(false);

  const logoPath = logo ? `/images/${logo}` : null;
  const handleViewClick = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    addReferreredVisit(
      companyName.toLowerCase().replace(/\s+/, "_") + "_visit"
    );
    window.location = link ? link : url;
  };

  const handleFindCareClick = () => {
    setCareModalOpen(true);
  };

  const handleCloseCareModal = () => {
    setCareModalOpen(false);
  };

  const handleMoreInfoClick = () => {
    setDropdownOpen((prev) => !prev);
  };

  const displaySettingsOffered = () => {
    if (inPersonLocations?.length && virtualLocations?.length)
      return (
        <>
          <InPersonIcon />
          &nbsp;&nbsp;&nbsp;
          <MonitorIcon />
        </>
      );
    if (inPersonLocations?.length)
      return (
        <>
          <InPersonIcon />
        </>
      );
    if (virtualLocations?.length)
      return (
        <>
          <MonitorIcon />
        </>
      );
  };

  return (
    <div className="card" style={{ boxSizing: "border-box" }}>
      <div className="card-padding">
        <div className="card-header">
          {logo ? (
            <div className="card-logo">
              <img src={logoPath} alt={` ${companyName} logo`} />
            </div>
          ) : (
            <p>{companyName}</p>
          )}
        </div>
        <div className="card-content">
          <div className="card-info">
            <div className="subheader flex flex-row items-center">
              <div className="price-subheader">
                <MoneyIcon />
                &nbsp;&nbsp;&nbsp;
                {price}
              </div>
              &nbsp;|{" "}
              <div>
                {availability === 0
                  ? "Available immediately"
                  : `Availability in
            ${availability} days`}{" "}
              </div>
              | <div className="">{displaySettingsOffered()}</div>
            </div>
          </div>
          <p>{description}</p>
          {priceDetail && (
            <div>
              <br />
              Price Detail:&nbsp;&nbsp;<span>{priceDetail}</span>
            </div>
          )}
        </div>
      </div>
      {!isDropdownOpen && (
        <div className="card-buttons">
          <Button
            type="secondary"
            extraClassName="border-radius narrow short referral"
            onClick={handleMoreInfoClick}
            analyticsContext={{
              label: "more info Button",
              description: "more info button clicked",
            }}
          >
            More info
          </Button>
          <Button
            type="primary"
            extraClassName="border-radius orange short wide"
            onClick={handleFindCareClick}
            analyticsContext={{
              label: "find care Button on ref card",
              description: "Access user profile and settings",
            }}
          >
            Get care
          </Button>
        </div>
      )}

      {/* Dropdown Details */}
      {isDropdownOpen && (
        <div className="details-dropdown">
          <button
            className="details-close-button"
            onClick={handleMoreInfoClick}
          >
            &times;
          </button>
          <div className="details-description">
            <p>{description}</p>
          </div>
          <div className="dropdown-card-buttons">
            <div className="dropdown-column">
              <div>
                <p className="sounds-right">Right for you?</p>
              </div>
              <Button
                type="primary"
                extraClassName="border-radius orange"
                onClick={handleFindCareClick}
              >
                Join {companyName}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Find Care Modal */}
      {isCareModalOpen && (
        <ReferralModal isOpen={isCareModalOpen} onClose={handleCloseCareModal}>
          <button className="close-button" onClick={handleCloseCareModal}>
            &times;
          </button>
          <div className="action-box">
            <p className="modal-description">
              You are now leaving Self. Click the link below to continue to:
            </p>
            <div>
              <a
                href={link ? link : url}
                className="link"
                onClick={handleViewClick}
              >
                {link ? link : url}
              </a>
            </div>
            <div className="description-details">
              <p className="description">{description}</p>
            </div>
            {/* <div className="modal-return">
              <Button
                type="primary"
                extraClassName="modal-return-button border-radius orange"
                onClick={handleCloseCareModal}
              >
                Return
              </Button>
            </div> */}
          </div>
        </ReferralModal>
      )}
    </div>
  );
};

export default ReferralCard;
